/*=========================
    Section Separation 
==========================*/

.slick-dotted.slick-slider {
    margin-bottom: 0;
}

.position-to-top {
    position: relative;
    margin-top: -300px;
}

.mt_dec--80 {
    margin-top: -80px;
}

.mt_dec--100 {
    margin-top: -100px;
}
.mt_dec--5 {
    margin-top: -5px !important;
}


.rbt-section-gap {
    padding: 120px 0;
    @media #{$md-layout} {
        padding: 80px 0;
    }
    @media #{$sm-layout} {
        padding: 80px 0;
    }
}

.rbt-section-gap2 {
    padding: 80px 0;
    @media #{$md-layout} {
        padding: 80px 0;
    }
    @media #{$sm-layout} {
        padding: 80px 0;
    }
}

.rbt-section-gapBottom {
    padding-bottom: 120px;
    @media #{$md-layout} {
        padding-bottom: 80px;
    }
    @media #{$sm-layout} {
        padding-bottom: 80px;
    }
}
.rbt-section-gap2Bottom {
    padding-bottom: 80px;
    @media #{$md-layout} {
        padding-bottom: 80px;
    }
    @media #{$sm-layout} {
        padding-bottom: 80px;
    }
}

.rbt-section-gapBottomHalf {
    padding-bottom: 60px;
}

.rbt-section-gapTop {
    padding-top: 120px;
    @media #{$md-layout} {
        padding-top: 80px;
    }
    @media #{$sm-layout} {
        padding-top: 80px;
    }
}

.rbt-section-gapTopHalf {
    padding-top: 60px;
}

.rbt-section-gap2Top {
    padding-top: 80px;
    @media #{$md-layout} {
        padding-top: 80px;
    }
    @media #{$sm-layout} {
        padding-top: 80px;
    }
}

.pb--165,
.pb--85 {
    @media #{$md-layout} {
        padding-bottom: 100px;
    }
    @media #{$sm-layout} {
        padding-bottom: 60px;
    }
}

.mt_dec--120 {
    margin-top: -120px !important;
}

.mt_dec--60 {
    margin-top: -60px !important;
}

.mt_dec--50 {
    margin-top: -50px !important;
}

.mt_dec--30 {
    margin-top: -30px !important;
}

.ml_dec_20 {
    margin-left: -20px !important;
}

.mt_dec--half {
    margin-bottom: -156px;
}



.default-callto-action-overlap {
    padding-top: 276px;
    @media #{$md-layout} {
        padding-top: 80px;
    }
    @media #{$sm-layout} {
        padding-top: 80px;
    }
}


.ptb--120 {
    @media #{$md-layout} {
        padding: 80px 0 !important;
    }

    @media #{$sm-layout} {
        padding: 60px 0 !important;
    }
}

.pb--165,
.pb--120,
.pb--85,
.pb--165 {
    @media #{$md-layout} {
        padding-bottom: 80px !important;
    }

    @media #{$sm-layout} {
        padding-bottom: 60px !important;
    }
}

.pb--70 {
    @media #{$sm-layout} {
        padding-bottom: 60px !important;
    }
}

.pl--0 {
    padding-left: 0 !important;
}

.pr--0 {
    padding-right: 0 !important;
}

.pt--0 {
    padding-top: 0 !important;
}

.pb--0 {
    padding-bottom: 0 !important;
}

.mr--0 {
    margin-right: 0 !important;
}

.ml--0 {
    margin-left: 0 !important;
}

.mt--0 {
    margin-top: 0 !important;
}

.mb--0 {
    margin-bottom: 0 !important;
}

.pt--260 {
    padding-top: 260px;
}

.pt--250 {
    padding-top: 250px;
}

.pb--240 {
    padding-bottom: 240px;
}

.pb--220 {
    padding-bottom: 220px;
}

.plr--270 {
    padding: 0 270px;
}

.plr--300 {
    padding: 0 300px;
}

.plr--340 {
    padding: 0 340px;
}
.plr--0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.ptb--300 {
    padding: 300px 0;
}
.ptb--340 {
    padding: 340px 0;
}
.ptb--450 {
    padding: 450px 0;
}
.plr_dec--15 {
    margin: 0 -15px;
}
.pt--300 {
    padding-top: 300px;
}
.pl--300 {
    padding-left: 300px;
}





@for $i from 1 through 40 {
    .ptb--#{5 * $i} {
        padding: 5px *$i 0 !important;
    }

    .plr--#{5 * $i} {
        padding: 0 5px *$i !important;
    }

    .pt--#{5 * $i} {
        padding-top: 5px *$i !important;
    }

    .pb--#{5 * $i} {
        padding-bottom: 5px *$i !important;
    }

    .pl--#{5 * $i} {
        padding-left: 5px *$i !important;
    }

    .pr--#{5 * $i} {
        padding-right: 5px *$i !important;
    }

    .mt--#{5 * $i} {
        margin-top: 5px *$i !important;
    }

    .mb--#{5 * $i} {
        margin-bottom: 5px *$i !important;
    }

    .mr--#{5 * $i} {
        margin-right: 5px *$i !important;
    }

    .ml--#{5 * $i} {
        margin-left: 5px *$i !important;
    }
}


@media #{$laptop-device} {
    @for $i from 1 through 20 {
        .ptb_lp--#{5 * $i} {
            padding: 5px *$i 0;
        }

        .plr_lp--#{5 * $i} {
            padding: 0 5px *$i;
        }

        .pt_lp--#{5 * $i} {
            padding-top: 5px *$i;
        }

        .pb_lp--#{5 * $i} {
            padding-bottom: 5px *$i;
        }

        .pl_lp--#{5 * $i} {
            padding-left: 5px *$i;
        }

        .pr_lp--#{5 * $i} {
            padding-right: 5px *$i;
        }

        .mt_lp--#{5 * $i} {
            margin-top: 5px *$i;
        }

        .mb_lp--#{5 * $i} {
            margin-bottom: 5px *$i;
        }
    }

    .plr--120 {
        padding: 0 80px !important;
    }
    .mt_lp_dec--30 {
        margin-top: -30px !important;
    }
}


@media #{$lg-layout} {
    .pl_lg--0 {
        padding-left: 0 !important;
    }

    .mt_lg_dec--30 {
        margin-top: -30px !important;
    }
    
    @for $i from 1 through 20 {
        .ptb_lg--#{5 * $i} {
            padding: 5px *$i 0 !important;
        }

        .plr_lg--#{5 * $i} {
            padding: 0 5px *$i !important;
        }

        .pt_lg--#{5 * $i} {
            padding-top: 5px *$i !important;
        }

        .pb_lg--#{5 * $i} {
            padding-bottom: 5px *$i !important;
        }

        .pl_lg--#{5 * $i} {
            padding-left: 5px *$i !important;
        }

        .pr_lg--#{5 * $i} {
            padding-right: 5px *$i !important;
        }

        .mt_lg--#{5 * $i} {
            margin-top: 5px *$i !important;
        }

        .mb_lg--#{5 * $i} {
            margin-bottom: 5px *$i !important;
        }

        .ml_lg--#{5 * $i} {
            margin-left: 5px *$i !important;
        }

    }
}

@media #{$md-layout} {
    .ptb--170 {
        padding: 80px 0 !important;
    }
    .mt_md_dec--30 {
        margin-top: -30px !important;
    }

    .pl_md--0 {
        padding-left: 0 !important;
    }
    .ptb_md--0 {
        padding: 0 !important;
    }

    .pl_md--0 {
        padding-left: 0 !important;
    }

    .pr_md--0 {
        padding-right: 0 !important;
    }

    .pt_md--0 {
        padding-top: 0 !important;
    }

    .pb_md--0 {
        padding-bottom: 0 !important;
    }

    .mr_md--0 {
        margin-right: 0 !important;
    }

    .ml_md--0 {
        margin-left: 0 !important;
    }

    .mt_md--0 {
        margin-top: 0 !important;
    }

    .mb_md--0 {
        margin-bottom: 0 !important;
    }

    .ptb_md--250 {
        padding: 250px 0 !important;
    }

    @for $i from 1 through 20 {
        .ptb_md--#{5 * $i} {
            padding: 5px *$i 0 !important;
        }

        .plr_md--#{5 * $i} {
            padding: 0 5px *$i !important;
        }

        .pt_md--#{5 * $i} {
            padding-top: 5px *$i !important;
        }

        .pb_md--#{5 * $i} {
            padding-bottom: 5px *$i !important;
        }

        .pl_md--#{5 * $i} {
            padding-left: 5px *$i !important;
        }
        .pr_md--#{5 * $i} {
            padding-right: 5px *$i !important;
        }
        .mt_md--#{5 * $i} {
            margin-top: 5px *$i !important;
        }
        .mb_md--#{5 * $i} {
            margin-bottom: 5px *$i !important;
        }
    }
}

@media #{$sm-layout} {
    .mt_sm_dec--30 {
        margin-top: -30px !important;
    }

    .ptb_sm--250 {
        padding: 250px 0 !important;
    }

    .ptb--170 {
        padding: 80px 0 !important;
    }

    .ptb_sm--0 {
        padding: 0 !important;
    }

    .pl_sm--0 {
        padding-left: 0 !important;
    }

    .pr_sm--0 {
        padding-right: 0 !important;
    }

    .pt_sm--0 {
        padding-top: 0 !important;
    }

    .pb_sm--0 {
        padding-bottom: 0 !important;
    }

    .mr_sm--0 {
        margin-right: 0 !important;
    }

    .ml_sm--0 {
        margin-left: 0 !important;
    }

    .mt_sm--0 {
        margin-top: 0 !important;
    }

    .mb_sm--0 {
        margin-bottom: 0 !important;
    }

    .pt_sm--150 {
        padding-top: 150px !important;
    }

    .pb_sm--110 {
        padding-bottom: 110px !important;
    }

    .ptb_sm--60 {
        padding: 60px 0 !important;
    }

    @for $i from 1 through 20 {
        .ptb_sm--#{5 * $i} {
            padding: 5px *$i 0 !important;
        }

        .plr_sm--#{5 * $i} {
            padding: 0 5px *$i !important;
        }

        .pt_sm--#{5 * $i} {
            padding-top: 5px *$i !important;
        }

        .pb_sm--#{5 * $i} {
            padding-bottom: 5px *$i !important;
        }

        .pl_sm--#{5 * $i} {
            padding-left: 5px *$i !important;
        }

        .pr_sm--#{5 * $i} {
            padding-right: 5px *$i !important;
        }

        .mt_sm--#{5 * $i} {
            margin-top: 5px *$i !important;
        }

        .ml_sm--#{5 * $i} {
            margin-left: 5px *$i !important;
        }

        .mr_sm--#{5 * $i} {
            margin-right: 5px *$i !important;
        }

        .mb_sm--#{5 * $i} {
            margin-bottom: 5px *$i !important;
        }
    }

    .pl_sm--0 {
        padding-left: 0;
    }

    .pr_sm--0 {
        padding-right: 0;
    }

    .pt_sm--0 {
        padding-top: 0;
    }

    .pb_sm--0 {
        padding-bottom: 0;
    }

    .mr_sm--0 {
        margin-right: 0;
    }

    .ml_sm--0 {
        margin-left: 0;
    }

    .mt_sm--0 {
        margin-top: 0;
    }

    .mb_sm--0 {
        margin-bottom: 0;
    }

}


@media #{$large-mobile} {
    @for $i from 1 through 20 {
        .ptb_mobile--#{5 * $i} {
            padding: 5px *$i 0 !important;
        }

        .plr_mobile--#{5 * $i} {
            padding: 0 5px *$i !important;
        }

        .pt_mobile--#{5 * $i} {
            padding-top: 5px *$i !important;
        }

        .pb_mobile--#{5 * $i} {
            padding-bottom: 5px *$i !important;
        }

        .pl_mobile--#{5 * $i} {
            padding-left: 5px *$i !important;
        }

        .pr_mobile--#{5 * $i} {
            padding-right: 5px *$i !important;
        }

        .mt_mobile--#{5 * $i} {
            margin-top: 5px *$i !important;
        }

        .mb_mobile--#{5 * $i} {
            margin-bottom: 5px *$i !important;
        }
    }
}



@for $i from 1 through 20 {
    .slick-gutter-#{$i * 5} {
        margin-left: -#{$i * 5}px;
        margin-right: -#{$i * 5}px;

        .slick-slide {
            padding-left: #{$i * 5}px;
            padding-right: #{$i * 5}px;
        }
    }
}