
.team-style-default,
.rbt-default-card {
    margin-top: 75px;
    position: relative;
    .inner {
        background: var(--color-white);
        border-radius: var(--radius);
        box-shadow: var(--shadow-1);

        .thumbnail {
            max-width: 150px;
            max-height: 150px;
            border-radius: 100%;
            border: 6px solid var(--color-border);
            top: -75px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            min-width: 150px;
            min-height: 150px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                overflow: hidden;
            }
        }
        .content {
            text-align: center;
            padding: 20px;
            padding-top: 85px;
            padding-bottom: 35px;

            .title {
                font-size: 30px;
                line-height: 1.4;
                font-weight: 700;
                margin-bottom: 6px;
                @media #{$lg-layout} {
                    font-size: 24px;
                }
                @media #{$md-layout} {
                    font-size: 24px;
                }
                @media #{$sm-layout} {
                    font-size: 28px;
                }
                @media #{$large-mobile} {
                    font-size: 22px;
                }
            }
            .subtitle {
                font-size: 24px;
                line-height: 1.4;
                margin-bottom: 15px;
                display: inline-block;
                @media #{$md-layout} {
                    font-size: 20px;
                }
                @media #{$sm-layout} {
                    font-size: 18px;
                }
            }
            .team-form {
                display: block;
                font-size: 14px;
                margin-bottom: 12px;
                letter-spacing: 0.6px;
                font-style: italic;
                img {
                    padding-right: 10px;
                    max-height: 18px;
                }
            }
            .description {
                margin-bottom: 0;
                padding: 0 5%;
            }
        }
        .social-icon {
            li {
                a {
                    color: var(--color-body);
                }
            }
        }
    }

    &.border-gradient {
        .thumbnail {
            background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98%/200% 100%;
            padding: 5px;
            border: 0 none;
        }
    }

    &.style-two {
        margin-top: 30px;
        .inner {
            padding-top: 35px;
            .thumbnail {
                top: 0;
                position: static;
                left: inherit;
                transform: none;
                min-width: 150px;
                min-height: 150px;
                margin: 0 auto;
            }
            .content {
                padding-top: 10px;
            }
        }
    }
    
    &.style-three {
        background: transparent;
        border-radius: 0;
        box-shadow: none;
        margin-top: 0;
        .inner {
            padding: 30px;
            height: 520px;
            @media #{$sm-layout} {
                padding: 15px;
                height: 400px;
            }
            @media #{$md-layout} {
                height: 400px;
            }
            .thumbnail {
                top: 0;
                position: static;
                left: inherit;
                transform: none;
                min-width: 100%;
                min-height: inherit;
                max-height: inherit;
                margin: 0 auto;
                border-radius: 6px;
                border: 0 none;
                img {
                    border-radius: 6px;
                    height: 250px;
                    object-fit: contain;
                }
            }
            .content {
                padding: 20px 0 0 0;
                .subtitle {
                    font-size: 24px;
                    line-height: 1.4;
                    margin-bottom: 15px;
                    display: inline-block;
                    @media #{$md-layout} {
                        font-size: 20px;
                    }
                    @media #{$sm-layout} {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    &.small-layout {
        .inner {
            padding: 20px;
            .content { 
                .title {
                    font-size: 20px;
                    margin-bottom: 2px;
                }
                .subtitle {
                    font-size: 16px;
                    margin-bottom: 6px;
                }
            }
        }
    }
}



.team {
    overflow: hidden;
    border-radius: 6px;
    position: relative;
    transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
    height: 400px;
    &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 85%;
        display: block;
        z-index: 1;
        content: '';
        background: linear-gradient(to bottom, rgba(15,15,15,0), rgba(15,15,15,0.75) 100%);
        transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
        cursor: pointer;
    }
    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 85%;
        display: block;
        z-index: 1;
        content: '';
        background: linear-gradient(to bottom, rgba(0,0,0,0), var(--color-primary) 100%);
        transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
        cursor: pointer;
        opacity: 0;
    }
    .thumbnail {
        display: block;
        position: relative;
        z-index: 1;
        img {
            width: 100%;
            @extend %transition;
        }
    }
    .content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 35px 40px;
        z-index: 2;
        @media #{$lg-layout} {
            padding: 10px 10px;
        }
        @media #{$sm-layout} {
            padding: 20px;
        }
        .title {
            color: #ffffff;
            margin: 0;
            opacity: 0;
            visibility: hidden;
            transition-delay: 0.25s;
            transform: translateY(10px);
            transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
            @media #{$lg-layout} {
                font-size: 22px;
            }
            a {
                color: #ffffff;
            }
        }
        .designation {
            color: #ffffff;
            font-size: 14px;
            line-height: 22px;
            opacity: 0;
            visibility: hidden;
            transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
            transform: translateY(10px);
        }
    }

    ul {
        &.social-icon {
            position: absolute;
            top: 25px;
            left: 35px;
            padding: 0;
            z-index: 2;
            list-style: none;
            display: flex;
            margin: 0 -10px;
            
            @media #{$lg-layout} {
                left: 10px;
            }

            @media #{$sm-layout} {
                left: 20px;
            }
           
            li {
                margin: 0 10px;
                transform: translateY(8px) scale(0.8);
                opacity: 0;
                visibility: hidden;
                transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);

                a {
                    color: #ffffff;
                    font-size: 15px;
                    transition: 0.3s;
                    svg{
                        transition: 0.3s;
                        fill: #ffffff;
                        height: 18px;
                        width: 18px;
                    }
                    &:hover{
                        svg{
                            transform: scale(1.3);
                        }

                    }
                }
                
            }
        }
    }

    &.team-style--bottom {
        ul {
            &.social-icon {
                top: auto;
                left: 40px;
                bottom: 117px;
                @media #{$sm-layout} {
                    bottom: 97px;
                    left: 20px;
                }
            }
        }
        .content  {
            h4 {
                &.title {
                    margin-bottom: 6px;
                }
            }
        }
        &.variation-2 {
            &::after,
            &::before {
                display: none;
            }
            .thumbnail {
                a {
                    display: block;
                    position: relative;
                    &::before {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 85%;
                        display: block;
                        z-index: 1;
                        content: "";
                        background: linear-gradient(to bottom, rgba(15, 15, 15, 0), rgba(15, 15, 15, 0.75) 100%);
                        transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
                        cursor: pointer;
                    }
                    &::after {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 85%;
                        display: block;
                        z-index: 1;
                        content: "";
                        background: linear-gradient(0deg,rgba(0,0,0,.85) 0%,rgba(0,0,0,0) 100%);
                        transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
                        cursor: pointer;
                        opacity: 0;
                    }

                }
            }
            .content {
                display: flex;
                align-items: center;
                .inner {
                    width: 80%;
                }
                .icon-right {
                    opacity: 0;
                    width: 20%;
                    text-align: right;
                    transition: opacity .45s,transform .45s;
                    transform: translateX(-20px);
                    visibility: hidden;
                    i {
                        color: var(--color-white);
                        font-size: 20px;
                    }
                }
            }
            &:hover {
                .thumbnail {
                    a {
                        &::before {
                            opacity: 0;
                        }
                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 25px 55px rgba(253, 71, 102, 0.22%);
        &::before {
            opacity: 0;
        }
        &::after {
            opacity: 1;
        }
        .content {
            .title {
                transition-delay: 0.25s;
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
            .designation {
                transition-delay: 0.33s;
                opacity: 0.7;
                visibility: visible;
                transform: translateY(0);
            }
        }


        ul {
            &.social-icon {
                li {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                    
                    &:nth-child(1){
                        -webkit-transition-delay: .07692s;
                        transition-delay: .07692s;
                    }

                    &:nth-child(2){
                        -webkit-transition-delay: .15385s;
                        transition-delay: .15385s;
                    }

                    &:nth-child(3){
                        -webkit-transition-delay: .23077s;
                        transition-delay: .23077s;
                    }

                    &:nth-child(4){
                        -webkit-transition-delay: .33077s;
                        transition-delay: .23077s;
                    }

                    &:nth-child(5){
                        -webkit-transition-delay: .43077s;
                        transition-delay: .23077s;
                    }
                }
            }
        }
        .content {
            opacity: 1;
            visibility: visible;
        }

        &.team-style--bottom {
            &.variation-2 {
                .content {
                    .icon-right {
                        opacity: 1;
                        transform: translateX(0);
                        visibility: visible;
                    }
                }
            }
        }
    }

}

// Slide For Swiper 
.swiper-slide {
    .team-style-default.style-three {
        margin: 30px;
        @media #{$lg-layout} {
            margin: 15px;
        }
        @media #{$md-layout} {
            margin: 15px;
        }
        @media #{$sm-layout} {
            margin: 15px;
        }
    }
}

.team-slide-activation-2 {
    .swiper-wrapper {
        margin: -30px 0;
        @media #{$lg-layout} {
            margin: -15px 0;
        }
        @media #{$md-layout} {
            margin: -15px 0;
        }
        @media #{$sm-layout} {
            margin: -15px 0;
        }
    }
}


/*---------------------
    Team Tab   
--------------------*/

.rbt-team-tab-thumb {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0px auto;
    margin-bottom: -30px;
    outline: none;
    border: 0 none;
    @media #{$large-mobile} {
        margin-bottom: -10px;
    }
    li {
        padding-left: 5px;
        padding-right: 5px;
        flex-basis: 33.33%;
        margin-bottom: 10px;
        outline: none;
        cursor: pointer;
        margin-top: 0;

        @media #{$sm-layout} {
            flex-basis: 33.33%;
            margin-bottom: 14px;
            padding-left: 7px;
            padding-right: 7px;
        }

        @media #{$large-mobile} {
            flex-basis: 50%;
            padding-left: 5px;
            padding-right: 5px;
            margin-bottom: 10px;
        }

        a {
            display: block;
        }
        .rbt-team-thumbnail {
            padding: 10px;
            background: var(--color-white);
            box-shadow: var(--shadow-2);
            border-radius: 6px;
            .thumb {
                position: relative;
                display: inline-block;
                width: 100%;
                img {
                    @extend %transition;
                    border-radius: 6px;
                    width: auto;
                    object-fit: cover;
                    height: 206px;
                    max-width: 100%;
                    @media #{$md-layout} {
                        width: 100%;
                        height: 270px;
                    }
                    @media #{$sm-layout} {
                        width: 100%;
                        height: auto;
                    }
                }
                &::after {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 0;
                    background: var(--gradient-7);
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    cursor: pointer;
                    border-radius: 6px;
                    @extend %transition;
                    opacity: 0;
                }
                &::before {
                    @extend %transition;
                    content: "\e94b";
                    position: absolute;
                    font-family: 'feather' !important;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: var(--color-white);
                    font-size: 18px;
                    z-index: 2;
                    font-size: 28px;
                    opacity: 0;
                    margin-left: 15px;
                }
            }
        }
        a {
            &.active {
                .thumb {
                    &::after {
                        background: var(--gradient-7);
                        opacity: 0.5;
                    }
                    &::before {
                        opacity: 1;
                        margin: 0;
                    }
                    img {
                        border-radius: 6px;
                        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
                        
                    }
                }
            }
        }
    }
}


.top-circle-shape {
    position: absolute;
    width: 240px;
    top: -96px;
    height: 240px;
    right: -96px;
    margin: 0 auto;
    background-image: linear-gradient(90deg, #CFA2E8, #637FEA);
    opacity: 0.09;
    border-radius: 100%;
    box-sizing: border-box;
    &::before {
        position: absolute;
        content: "";
        background: #fff;
        border-radius: 100%;
        width: calc(100% - 60px);
        height: calc(100% - 60px);
        left: 30px;
        top: 30px;
    }
    &.position-bottom-right {
        bottom: -96px;
        right: -96px;
        top: auto;
    }
}

.top-circle-shape-top {
    .top-circle-shape {
        top: 0;
    }
    &.top-circle-shape-top-left {
        .top-circle-shape {
            top: 0;
            right: auto;
            left: -96px;
        }
    }
}

.rbt-team-tab-content {
    background: var(--color-white);
    box-shadow: var(--shadow-1);
    padding: 26px;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    .inner {
        display: flex;
        align-items: center;
        position: relative;
        @media #{$sm-layout} {
            display: block;
        }
    }
    .author-info {
        margin-bottom: 20px;
        @media #{$md-layout} {
            margin-bottom: 10px;
        }
        @media #{$sm-layout} {
            margin-bottom: 10px;
        }
        .title {
            margin-bottom: 7px;
        }
        .designation {
            display: block;
            font-size: 19px;
            font-weight: 500;
            margin-bottom: 12px;
        }
    }
    .rbt-team-thumbnail {
        flex-basis: 44%;
        .thumb {
            img {
                border-radius: 6px;
                height: auto;
                object-fit: cover;
                width: 100%;
            }
        }
    }
    .rbt-team-details {
        flex-basis: 56%;
        padding-left: 30px;
        @media #{$sm-layout} {
            padding-left: 0;
            margin-top: 20px;
        }
        p {
            line-height: 28px;
            margin-bottom: 25px;
            @media #{$sm-layout} {
                margin-bottom: 10px;
            }
        }
        .team-form {
            display: block;
            font-size: 14px;
            margin-bottom: 12px;
            letter-spacing: 0.6px;
            font-style: italic;
            img {
                padding-right: 10px;
                max-height: 18px;
            }
        }
        .rbt-information-list {
            li {
                a {
                    font-size: 16px;
                }
            }
        }
    }
}

/*---------------------------
    Team Modal Style  
-----------------------------*/

.rbt-team-modal-thumb {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0px auto;
    margin-bottom: 0;
    outline: none;
    border: 0 none;
    @media #{$large-mobile} {
        margin-bottom: -10px;
    }
    .rbt-team-thumbnail {
        padding: 10px;
        background: var(--color-white);
        box-shadow: var(--shadow-2);
        border-radius: 6px;
        display: block;
        .thumb {
            position: relative;
            display: inline-block;
            width: 100%;
            img {
                @extend %transition;
                border-radius: 6px;
                width: auto;
                object-fit: cover;
                max-width: 100%;
            }

            &::after {
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                background: var(--gradient-7);
                width: 100%;
                height: 100%;
                z-index: 1;
                cursor: pointer;
                border-radius: 6px;
                @extend %transition;
                opacity: 0;
            }
            &::before {
                @extend %transition;
                content: "\e94b";
                position: absolute;
                font-family: 'feather' !important;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: var(--color-white);
                font-size: 18px;
                z-index: 2;
                font-size: 28px;
                opacity: 0;
                margin-left: 15px;
            }
        }
        &:hover {
            .thumb {
                &::after {
                    background: var(--gradient-7);
                    opacity: 0.5;
                }
                &::before {
                    opacity: 1;
                    margin-left: 0;
                }
            }
        }
    }
}

